<div *ngIf="!filter || hasFilterHit" class="my-2">
  <div class="d-flex flex-row align-items-center flex-wrap">
    <label class="plusminus text-secondary me-2" (click)="collapsed=!collapsed">
      <span *ngIf="!hasFilterHit && needsCollapse()" class="pointer">
        <span *ngIf="collapsed"><i class="fas fa-plus"></i></span>
        <span *ngIf="!collapsed"><i class="fas fa-minus"></i></span>
      </span>
      <span *ngIf="filter" class="text-low"><i class="far fa-filter"></i></span>
    </label>
    <div class="custom-control custom-checkbox me-2">
      <div class="form-check noselect">
        <input type="checkbox" class="form-check-input" [ngModel]="getValue(keyValuePair.value, depth) === 1"
          (ngModelChange)="change($event)" [indeterminate]="getValue(keyValuePair.value, depth)<0"
          [id]="'customCheck' + getUniqueKey()">
        <label class="form-check-label noselect pointer" [for]="'customCheck' + getUniqueKey()">
          <app-filtered-text [text]="keyValuePair.key" [filterRegex]="filter"></app-filtered-text>
        </label>
      </div>
    </div>
    <ng-container *ngIf="!fixedCatalog">
      <span class="catalog-badge small" *ngFor="let catalog of getCatalogs(keyValuePair.value, depth)"><span
          class="badge text-bg-secondary">{{catalog}}</span></span>
    </ng-container>
  </div>
  <ng-container *ngIf="!fixedCatalog || depth<1">
    <div *ngIf="(!collapsed && !filter) || hasFilterHit" [@slideInOut] class="collapsable">
      <div *ngFor="let kvp of keyValuePair.value | keyvalue">
        <app-catalog-line-checkbox [keyValuePair]="kvp" [parentKey]="getUniqueKey()" [filter]="filter"
          [parentFilterHit]="isThisFilterHit(keyValuePair)" [fixedCatalog]="fixedCatalog" [uniqueId]="uniqueId"
          [depth]="depth+1">
        </app-catalog-line-checkbox>
      </div>
    </div>
  </ng-container>
</div>
