import { Component, Input, OnInit } from '@angular/core';
import { BackendInfo } from '../../../../_models/admin/settings/backend.info';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-admin-backend-info',
  templateUrl: './admin-backend-info.component.html',
  styleUrls: ['./admin-backend-info.component.scss']
})
export class AdminBackendInfoComponent implements OnInit {
  @Input() backendInfo: BackendInfo;
  @Input() latest: string;
  @Input() json: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  isDateMinValue(date: Date): boolean {
    return (date.getTime() === -62135597972000);
  }

  getSatusJson(json: string): any {
    if (json && json.includes('"name":')) return JSON.parse(json);
    return { name: '', environment: '' };
  }


}
