<div class="alert alert-success">
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-4 fw-bold">Orderdatum</div>
        <div class="col-8 font-italic">{{order.CreatedAt | date:"dd-MM-yyyy HH:mm"}}</div>
        <div class="col-4 fw-bold">Herkomst</div>
        <div class="col-8">
          <app-archive-order-kind-badge [archiveOrderKind]="order.OrderKind"
            [orderType]="order.OrderType"></app-archive-order-kind-badge>
        </div>
        @if (order.OrderNumber > 0) {
        <div class="col-4 fw-bold"><span class="align-middle">Ordernumber</span></div>
        <div class="col-8">
          <button class="btn btn-xs btn-info"
            (click)="mainService.saveToClipBoard(order.OrderNumber.toString())">{{order.OrderNumber}} <span
              class="ms-2"><i class="far fa-copy"></i></span></button>
        </div>
        }
        @if (order.AdditionalOrderNumbers?.length) {
        <div class="col-4 fw-bold"><span class="align-middle">Additional Ordernrs</span></div>
        <div class="col-8">
          @for (additional of order.AdditionalOrderNumbers; track $index) {
          <button class="btn btn-xs btn-danger" [routerLink]="['/admin/superadmin/admin-order-info', additional]">{{additional}} <span class="ms-2"><i class="fa-solid fa-up-right-from-square"></i></span></button>
          }
        </div>

        }
        <div class="col-4 fw-bold"><span class="align-middle">Order ID</span></div>
        <div class="col-8">
          <button class="btn btn-xs btn-success"
            (click)="mainService.saveToClipBoard(order.OrderId)">{{order.OrderId}} <span class="ms-2"><i
                class="far fa-copy"></i></span></button>
        </div>
        <div *ngIf="order.Timing" class="col-12">
          <button class="btn btn-xs btn-light mt-2" (click)="mainService.showTimingDialog(order.Timing)">Order creation
            timings <span class="ms-2"><i class="far fa-stopwatch"></i></span></button>
        </div>

      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-4 fw-bold">Order voor</div>
        <div class="col-8 font-italic">{{branchName}}</div>
        <div class="col-4 fw-bold">Order van</div>
        <div class="col-8 font-italic">
          {{customer.Name}} <br />
          {{customer.StreetName}} {{customer.HouseNumber}}<br />
          {{customer.PostalCode}}&nbsp;&nbsp;{{customer.City}}
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="card" app-archive-json-panel [title]="'Request JSON'" [json]="order.Request"></div>
    </div>
    <div class="col-12 mt-3">
      <div class="card" app-archive-json-panel [title]="'Response JSON'" [json]="order.Response"></div>
    </div>
  </div>
</div>