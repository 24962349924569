<app-admin-form [title]="'Bright instellingen'" [object]="settings" [withSettingsSelector]="true" (settingsChanged)="settings=$event">

  @for (supplier of adminSettingsService.getAdminSetting(settings,'BrightSuppliers').Value; track $index) {
    <app-admin-supplier-input 
      [label]="adminSettingsService.getAdminSetting(settings, supplier).Description" 
      [(model)]="adminSettingsService.getAdminSetting(settings, supplier).Value" 
      [noneSelectionLabel]="'Geen leverancier geselecteerd.'">
    </app-admin-supplier-input>
  }

  <app-admin-supplier-input [label]="'GAIK leverancier'" [(model)]="adminSettingsService.getAdminSetting(settings,'Gaik_LeverancierNr').Value" [noneSelectionLabel]="'Geen leverancier geselecteerd.'"></app-admin-supplier-input>
  <app-admin-item-groups-input [label]="'GAIK artikelgroepen'" [(model)]="adminSettingsService.getAdminSetting(settings,'Gaik_ItemGroups').Value" [noneSelectionLabel]="'Geen artikelgroepen geselecteerd.'" [dropup]="true"></app-admin-item-groups-input>
  <app-admin-dropdown [label]="'GAIK artikel veld'" [(model)]="adminSettingsService.getAdminSetting(settings,'Gaik_ArticleField').Value" [modelDisplayValues]="adminSettingsService.getAdminSetting(settings,'Gaik_ArticleFieldKinds').Value" [modelEmptyDisplayValue]="'Niets geselecteerd'"></app-admin-dropdown>
  
  <app-admin-dropdown [label]="'Promolabel ResponseItem property'" [(model)]="adminSettingsService.getAdminSetting(settings,'BrightPromoLabelResponseItemProperty').Value" [modelDisplayValues]="adminSettingsService.getAdminSetting(settings,'BrightPromoLabelResponseItemPropertyKinds').Value" [modelEmptyDisplayValue]="'Niets geselecteerd'"></app-admin-dropdown>

  <app-admin-item-groups-input [label]="'Artikelgroepen voor supplier-stocks.csv'" [(model)]="adminSettingsService.getAdminSetting(settings,'BrightSupplierStocks_ItemGroups').Value" [noneSelectionLabel]="'Geen artikelgroepen geselecteerd.'" [dropup]="true"></app-admin-item-groups-input>

  <app-admin-cancel-save-button [saveAction]="adminSettingsService.saveSettingsBase(settings)"></app-admin-cancel-save-button>
</app-admin-form>
