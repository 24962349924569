<div *ngIf="backendInfo" class="row mb-3">
  <div class="col-5">
    <div *ngIf="!json" class="fw-bold">{{backendInfo.Description}}</div>
    <div *ngIf="json" class="fw-bold">{{getSatusJson(backendInfo.Description).name}} {{getSatusJson(backendInfo.Description).environment}}</div>
    <div><a [href]="backendInfo.Url" target="_blank">{{backendInfo.Url}}</a></div>
  </div>
  <div *ngIf="!json" class="col-7">
    <div class="fw-light" *ngIf="backendInfo.Version">
      <span *ngIf="backendInfo.Version<latest" class="text-warning me-1"><i
          class="fas fa-exclamation-triangle"></i></span>
      <span *ngIf="backendInfo.Version>=latest" class="text-success me-1"><i class="fas fa-badge-check"></i></span>
      <span class="badge text-bg-secondary">{{backendInfo.Version}}</span>
      <span *ngIf="backendInfo.Environment" class="ms-1 badge text-bg-primary">{{backendInfo.Environment}}</span>
      <span class="ms-1 fw-light font-italic"
        *ngIf="!isDateMinValue(backendInfo.VersionDate)"><br />{{backendInfo.VersionDate | date : 'EEEE d MMMM y HH:mm'}}</span>
    </div>
    <div class="font-italic text-danger" *ngIf="backendInfo.ErrorMessage">{{backendInfo.ErrorMessage}}</div>
  </div>
  <div *ngIf="json" class="col-7">
    <div class="fw-light" *ngIf="getSatusJson(backendInfo.Description).version">
      <span class="badge text-bg-secondary">{{getSatusJson(backendInfo.Description).version}}</span>
      <span *ngIf="backendInfo.Environment" class="ms-1 badge text-bg-primary">{{backendInfo.Environment}}</span>
      <span class="ms-1 fw-light font-italic"
        *ngIf="!isDateMinValue(getSatusJson(backendInfo.Description).buildDate)"><br />{{getSatusJson(backendInfo.Description).buildDate | date : 'EEEE d MMMM y HH:mm'}}</span>
    </div>
    <div class="font-italic text-danger" *ngIf="getSatusJson(backendInfo.Description).message!='OK'">{{getSatusJson(backendInfo.Description).message}}</div>
  </div>
</div>